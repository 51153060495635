import { defineEventHandlers, postEvent, isTMA } from '@telegram-apps/bridge';

isTMA()
    .then((value) => {
        if(value) {
            defineEventHandlers();

            postEvent('web_app_expand');
        }
    })
    .catch(console.error);
